@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.auth-right {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 40vw;
}

.right-width {
  position: relative;
  width: 100%;
  .up-arrow-container-right {
    position: absolute;
    width: 100%;
    z-index: 100;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    img {
      height: 80%;
    }
  }
}

.auth-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  // border: 2px solid red;
}

.auth-right-heading {
  @include flexcenter();
  font-size: xx-large;
  font-weight: bold;
  // text-align: center;
  width: 70%;
}
.auth-right-text {
  width: 70%;
  font-size: large;
  @include flexcenter();
  // text-align: center;
  margin-bottom: 13%;
}

@media only screen and (max-width: $mobile-screen-width-max) {
  .auth-right {
    display: none;
  }
}

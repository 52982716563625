@import "../../assets/styles/variables";

.hidden{
  display: none !important;
}
.nav {
  width: 14.5vw;
  height: 100vh;
  background-color: #ffffff;
  /* Set your desired background color */
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  /* Add shadow for a subtle effect */
  transition: width 0.3s ease;
  /* Add transition for smooth animation */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* Add this for the collapsed state */
.nav.collapsed {
  width: 4vw;
}


.nav-logo-collapsed {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  // border: 2px solid red;

  img {
    width: 1.5vw;
    // aspect-ratio: 3/4;
  }
}

.nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;

  img {
    width: 10vw;
  }
}

.nav-top-menu {
  margin-top: 20%;
}

.nav-top-menu.collapsed {
  margin-top: 50%;
}

.nav-main-item-box {
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: 0.35rem;
}

.nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 85%;
  height: 5vh;
  margin-bottom: 1%;
  padding-left: 15%;
  cursor: pointer;
  column-gap: 0.5rem;
}

.nav-item.centered {
  // border: 2px solid red;
  width: 100%;
  justify-content: center;
  padding: 0;
  /* Remove right padding */
}

.nav-icon {
  margin-right: 3%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 1.1vw;
    /* Adjust the icon size */
    
  }
}

.nav-route {
  font-size: 1.1vw;
  font-family: 'Nunito Sans';
  font-weight: 1000;
}

.nav-bottom-menu {
  margin-bottom: 15%;
}


@media screen and (max-width: 767px) {

  .nav-top-menu {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  // .nav-bottom-menu {
  //   margin-bottom: 30%;
  // }

  .nav {
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 1000;
    width: 9.5rem;
    height: auto;
    // row-gap: 1rem;
    // height: 27rem;
    border-radius: 0px 10px 10px 0px;
    transition: transform 0.5s ease-in-out;
  }

  .nav-logo img {
    width: 20vw;
  }

  .nav-icon img {
    width: 1rem;
  }

  .nav-route {
    font-size: 1rem;
  }

}

.black-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.3;
  z-index: 1;

}
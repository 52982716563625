.timer-main {
    height:100vh;
    width: 100%;
    // border: 2px solid red;
}

.timer-position{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    height: 100vh !important;
}
.timer-main-mobile {
    // height:90vh;
    width: 100%;
    // border: 2px solid red;
}

.timer-top-bar {
    width: 100%;
    height: 14.5vh;
    // border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7vh;
}

// changed for mobile
@media screen and (max-width: 768px){
    
    .timer-main-mobile{
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // overflow-y: scroll;
        scroll-behavior: smooth;
        padding-bottom: 3rem;
    }

    .timer-top-bar{
        height: auto;
        margin-bottom: 0;
    }
    
}

.timer-top-left {
    // border: 2px solid red;
    display: flex;
    margin-left: 2.3%;
    // width: 13vw;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.timer-top-right {
    margin-right: 2.3%;
}

@media screen and (max-width: 768px){
    .timer-top-left{
        gap: 0.5rem;
        margin-left: 5%;
    }

    .timer-top-right {
        margin-right: 5%;
    }
    .no-select{
        user-select: none;
        -webkit-touch-callout : none;
        -webkit-user-select:none
    }
}


.cube-type-select {
    select {
        cursor: pointer;
        border-radius: 10px;
        border: none;
        padding: 2px;
        width: fit-content;
        padding: 5px 2px;
        outline: none;
    }

}
.session-select {
    select {
        cursor: pointer;
        border-radius: 10px;
        border: none;
        padding: 5px 2px;
        width: 8vw;
        outline: none;
    }
}

.focus-button {
    cursor: pointer;
    // background-color: #692bcb;
    // width: 9vw;
    padding: 10px 10px;
    border-radius: 3px;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: "Nunito Sans";
    font-weight: 700;
}

@media screen and (max-width: 768px){
    .focus-button{
        width: auto;
        padding: 9px 9px;
    }

    // .timer-overlay{
    //     position: fixed;
    //     height: 100vh;
    //     width: 100vw;
    //     background-color: rgba(0,0,0,0.5);
    //     z-index: 100;
    // }
    
}

.focus-image{
    width: 1rem;
    margin-left: 0.5rem;
}

@media screen and (max-width: 768px){
    .focus-image{
        // border: 2px solid red;
        // margin-left: 5px;
        width:  1.25rem;
        margin-left: 0;
    }
    
}


.driver-popover{
    background: rgba(10, 0, 58, 1) !important;
    color: white !important;
    border-radius: 10px !important;
    padding: 20px !important;
}

.driver-popover-title{
    font: 21px !important;
}

.driver-popover-description{
    color: gray !important;
}

.driver-popover-footer button{
    padding: 6px 11px !important;
    font: 14px !important;
}

:not(body):has(>.driver-active-element) {
    overflow: visible /* or auto, or any value you prefer */
}

.no-selector{
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    position: absolute;
    top:15%;
}
.learning-container {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

@media screen and (max-width: 767px) {
    .learning-container {
        padding-bottom: 3rem;
        margin-bottom: 3rem;
        margin-top: 5rem;
    }

    .filter-dropdown{
        width: 150px !important;
    }
    
}

.learning-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    // color: #ffffff;
}

.learning-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 1rem;
    padding: 12px 10px;
    font-size: 0.9rem;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .learning-btn {
       display: none;
    }
    
}

.learning-btn button {
    cursor: pointer;
    height: 32px;
    padding: 5px 15px 5px 15px;
    border-radius: 44.44px;
    gap: 5px;
}

.learning-content {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    padding: 2rem;
    gap: 1rem;
    
}

.learning-content-box {
    display: flex;
    column-gap: 0.5rem;
    justify-content: space-between;
    flex: 0 1 calc(33.33% - 60px);
    padding: 15px;
    text-align: center;
    border-radius: 12px;
}

@media (max-width: 768px) {

    .learning-btn,
    .learning-heading {
        padding: 0 1rem;
    }

    .learning-content {
        padding: 2rem 1rem;
        padding-top: 1rem;
    }

    .learning-content {
        gap: 0.5rem;
    }

    .learning-content-box {
        flex: 0 1 calc(50% - 45px);
    }
}

/* Media query for even smaller screens */
@media (max-width: 640px) {
    .learning-content-box {
        flex: 1 1 100%;
    }
}

.learning-content-box-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
}

.learning-content-box-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.learning-content-box-label {
    font-family: Nunito Sans;
    width: fit-content;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    // color: #FFFFFF;
    background: #FFFFFF80;
    padding: 0.2rem 0.5rem;
    border-radius: 2px;
}

.learning-content-box-buttons {
    display: flex;
    column-gap: 1rem;
}

.learning-content-box-buttons-dot {
    cursor: default;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    background: #C19BFD80;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    // color: #FFFFFF;
}

.learning-content-box-buttons-edit {
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    color: #FFFFFF;
    background: #AA4FF6;
    border-radius: 2px;
}

.learning-content-box-solve-id {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.09em;
    text-align: left;
    // color: #FFFFFF;
}

.select-buttons {
    display: flex;
    column-gap: 1rem;
    // padding: 1rem 2rem;
}

.learning-option {
    width: 100%;
}

.learning-cube-type-select {
    cursor: pointer;
    width: 4rem;
    border-radius: 44px;
    border: none;
    height: 100%;
    outline: none;
    padding: 0.4rem;
}

.learning-cube-type-select option {
    background-color: #C19BFD;
}

.learning-no-solves-div {
    // background-color: rgba(10, 0, 58, 0.2);
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
}
.matrix-cubetype2{
    max-height: 13vh;
    height: fit-content;
}

.text-color-light{
    color: white !important;
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px 10.96px 10.96px 0px;
    // background: rgba(255, 255, 255, 0.4);
  }
  
  #style-1::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px 10.96px 10.96px 0px;
    // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
    background: rgba(44, 44, 44, 0.1);
  
  }
  
  #style-1::-webkit-scrollbar-thumb {
    padding: 5px 0;
    border-radius: 0px 10.96px 10.96px 0px;
    background: rgba(0, 0, 0, 0.574);
  }
  
@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.signup-left {
  margin: 0;
  padding: 0;
  height: 100%;
  flex: 3;
}
.up-arrow-container-left {
  display: flex;
  align-items: flex-end;
  position: absolute;
  z-index: 100;
  height: 100%;
  img {
    height: 80%;
  }
}

.signup-left-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.signup-left-heading {
  @include flexcenter();
  font-size: xx-large;
  font-weight: bold;
  // border: 2px solid red;
  width: 30vw;
}

.signup-left-text {
  width: 70%;
  font-size: large;
  text-align: center;
  @include flexcenter();
  margin-bottom: 13%;
}

@media only screen and (max-width: $mobile-screen-width-max) {
  .signup-left {
    display: none;
  }
}

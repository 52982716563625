/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.focus-btn-desktop{
    display: block;
}

.focus-btn-mobile{
    display: none;
}

.desktop-profile-form{
    display: block;
}

.profile-form-content-mobile{
    display: none;
}

@media screen and (max-width: 768px) {
    .profile-container {
        margin-top: 5rem;
    }
}

.profile-container {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.profile-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.profile-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.profile-btn {
    padding: 0rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
    padding-top: 1rem;

}

.profile-data-mobile{
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 700;
}

@media (max-width: 768px) {

    .focus-btn-desktop{
        display: block;
    }
    
    .focus-btn-mobile{
        display: block;
    }

    .desktop-profile-form{
        display: none;
    }

    .profile-form-content-mobile{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 1rem;
        
    }

    .profile-btn,
    .profile-heading {
        padding: 0 1rem;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    .profile-btn {
        -webkit-column-gap: 0.4rem;
           -moz-column-gap: 0.4rem;
                column-gap: 0.4rem;
        row-gap: 1rem;
    }
}

.profile-form {
    padding: 1rem;
}

.profile-form-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px 20px;
}

.profile-form-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    margin: 3rem 0rem;
}

.profile-date-picker {
    width: 97%;
    
}

.profile-date-picker input {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .profile-date-picker {
        width: 95%;
    }

}

.profile-date-picker-light {
    background: rgba(222, 222, 222, 1) !important;
    width: 97%;
}

@media screen and (max-width: 768px) {
    .profile-date-picker-light {
        width: 95%;
    }

}

.info-profile-calendar {
    position: absolute;
    z-index: 9;
    font-size: 1.1rem;
    right: 0.5rem;
    top: 2.2rem;
}

.profile-country-dropdown,
.profile-city-dropdown {
    background: #FFFFFF8C;
    border: none;
    height: 38px;
    border-radius: 5px;
    font-size: 18px;
    padding: 0.2rem 0.5rem;
}

.profile-country-dropdown:focus,
.profile-city-dropdown:focus {
    outline: none;
}

.profile-password-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 1rem;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 50%;
}

.password-check-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.password-list-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 0.3rem;
       -moz-column-gap: 0.3rem;
            column-gap: 0.3rem;
}

.password-list-text {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFFCC;
}

.password-list-checkbox {
    color: #FFFFFFCC;
}

@media (max-width: 768px) {

    .profile-form-content {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }

    .profile-form {
        padding: 2rem 1rem;
    }

    .profile-password-form {
        width: 100%;
    }

    .profile-country-dropdown,
    .profile-city-dropdown {
        width: 100%;
    }
}

.profile-details-container {
    padding: 2rem;
}

@media screen and (max-width: 768px) {
    .profile-details-container {
        padding: 2rem 1rem;
    }

}

.profile-banner {
    position: relative;
    height: 240px;
    width: 100%;

}

.profile-img {
    position: absolute;
    height: 240px;
    width: 100%;
}

.profile-details {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem;
    width: 100%;
    
    padding-left: 2rem;
}

.profile-name {
    font-family: Nunito Sans;
    font-size: 52px;
    font-weight: 700;
    line-height: 71px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    text-transform: capitalize;
}


.profile-edit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.profile-details-bottom-section {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 0.2rem;
    padding-top: 1rem;
}

.profile-details-heading-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.profile-details-heading-section h1 {
    font-family: Nunito Sans;
    font-size: 26px;
    font-weight: 700;
    line-height: 5px;
    letter-spacing: 0em;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .profile-details-heading-section h1 {
        line-height: 25px;
    }

    .profile-details-heading-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-box-align: self-start;
            -ms-flex-align: self-start;
                align-items: self-start;
    }

}



.profile-buttons-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
}

.profile-details-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 5px 10px 5px 10px;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}


.profile-bio-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.profile-bio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.profile-bio-text {
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: 5px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.profile-bio-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 25%;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 3px 10px;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}



.profile-modal {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height: 100vh;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 1003;
    
}

.profile-form-padding{
    margin: 0 2rem;
    border-radius: 12px;
}

@media screen and (max-width: 768px){

    .profile-form-padding{
        margin: 0 1rem;
    }

    .delete-height{
        height: 100% !important;
    }
}

// .profile-modal-container::before {
//     content: '';
//     position: absolute;
//     z-index: -1;
//     top: 0;
//     left: 0;
//     height: 100vh;
//     width: 100%;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//         -ms-flex-align: center;
//             align-items: center;
//     -webkit-box-pack: center;
//         -ms-flex-pack: center;
//             justify-content: center;
//     background-color: black;
//     opacity: 0.5;
    
// }

@media screen and (max-width: 768px){
   .profile-modal-container {
        margin-bottom: 0;
        margin-top: 0;
        height: 100vh;
   }
}

.profile-modal-container {
    padding: 0rem 2rem;
    width: 50%;
    border-radius: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        // justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
        padding: 0.5rem 2rem; /* Adjust padding as needed */
    width: 50%; /* Adjust width as needed */
    max-height: 100%; /* Allow modal to occupy full height */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
    border-radius: 13px;
}

.delete-modal-width{
    width: 30%;
}

.profile-edit-heading {
    font-family: Nunito Sans;
    font-size: 22px;
    font-weight: 900;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.close-button {
    color: #6b46c1;
    position: absolute;
    margin-top: 5px;
    margin-right: -15px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    aspect-ratio: 1;
}


.close-button svg {
    width: 25px;
    height: 27px;
    color: black;
}

.close-button:hover {
    color: #805ad5;
}

.profile-cube-boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
}

.profile-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0.3rem 1rem;
    width: 8rem;
    border-radius: 5px;
}

.profile-box-heading {
    font-family: Nunito Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.profile-box-value {
    font-family: Nunito Sans;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    width: 100%;
}

.profile-form-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 0.1rem;
}

.profile-form-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 40px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 40px;
    margin: auto;
}

.profile-form-div label {
    font-family: Nunito Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 1px;
    text-align: left;
}

.profile-form-div input {
    background: rgba(255, 255, 255, 0.5490196078);
    border: none;
    height: 30px;
    border-radius: 5px;
    font-size: 18px;
    padding: 0.2rem 0.5rem;
}

.profile-form-div textarea {
    border-radius: 5px;
    height: 70px;
    background: rgba(255, 255, 255, 0.5490196078);
    border: none;
    font-size: 18px;
    padding: 0.2rem 0.5rem;
}

@media screen and (max-width: 768px) {
    .profile-cube-boxes {
        display: none;
    }

    .profile-details {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: self-start;
            -ms-flex-align: self-start;
                align-items: self-start;
    }

    .profile-name {
        line-height: 0;
    }

    .profile-modal-container {
        padding: 0rem 1rem;
        width: 80%;
    }

    .delete-modal-width{
        width: 80%;

        height: 23%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 10px
    }

    .profile-form-content {
        -ms-grid-columns: 1fr;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 7px 13px;
    }

    .profile-label-hidden-mob {
        display: none;
    }

    .close-button svg {
        width: 20px;
    }

    .close-button{
        top: 10px;
        width: 11%;
    }
    .close-button {
 margin-right: 5px;
    }
    .logout-inside-cont{
        margin-top: 10%;
    }

    
}

.profile-social-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    top: 2rem;
}

.profile-social-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0.5rem;
    border-radius: 50%;
    background: #FFFFFF;
}

.profile-field{
    height: 36.4px !important;
}

.stdropdown-input input {
    height: 2.25rem !important;
}

 input::placeholder {
    color: #555; /* Darker gray color */
    opacity: 1; /* Full opacity */
}

input:-ms-input-placeholder {
    color: #555;
}

input.disabled-input{
    cursor: default;
}
.css-66gjpw-MuiResponsiveChart-container>svg {
    position: absolute !important;
    left: 12% !important;
}

@media screen and (max-width: 768px) {
    .css-66gjpw-MuiResponsiveChart-container>svg {
        left: 30% !important;
    }
    .stats-container {
        margin-top: 5rem;
    }
}

.stats-container {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

.stats-container::-webkit-scrollbar {
    display: none;
}

.stats-header {
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

@media screen and (max-width: 768px) {
    .stats-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
}

.stats-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    // color: #ffffff;
}

.stats-btn {
    padding: 0rem 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.stats-btn-button {
    font-size: 1rem;
    cursor: pointer;
    width: 108px;
    height: 32px;
    // padding: 5px 10px;
    border-radius: 8px;
    gap: 5px;
}

.stats-content {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
}

.stats-overview,
.stats-event,
.stats-average {
    width: 50%;
    display: flex;
    flex-direction: column;
    // row-gap: 1.5rem;
}

.stats-event {
    width: 40%;
}

.stats-overview h1,
.stats-event h1,
.stats-average h1 {
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    // color: #FFFFFF;
}

.stats-overview-content {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px 15px;
    border-radius: 12px;
}

.stats-box {
    height: 180px;
    border-radius: 10.8px;
}

.stats-box-container {
    height: auto;
    border-radius: 10.8px;
}

.stats-box-content {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.stats-box-content-heading {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    text-transform: capitalize;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    background: #FFFFFF;
    border-radius: 5.4px;
}

.stats-box-content-heading h1 {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    // line-height: 0px;
    letter-spacing: 0em;
    text-align: left;
    // color: #FFFFFF;
}

.stats-time-spent-content,
.stats-total-solve-content,
.stats-dynamic-box-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #00CC00;
}

.stats-dynamic-box-content {
    font-size: 16px;
    color: #FFFFFF;
}

.stats-icons{
    font-size: 1.25rem;
}

@media screen and (max-width: 768px) {
    .stats-icons{
        font-size: 1rem;
    }

    .stats-total-solve-content {
        margin-top: 1.1rem;
     }
     
}


.stats-event-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.stats-event-box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7rem;
    padding: 0.5rem;
    border-radius: 12px;
    position: relative;
}

.text-top-right {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-transform: capitalize;
    text-align: left;
    color: #FFFFFF;
}

@media screen and (max-width: 768px) {
    .text-top-right {
        left: 0;
    }
}
.stats-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
}


.stats-overview-matrix-content {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 15px 15px;
    border-radius: 12px;
}

.stats-matrix-box {
    border-radius: 10.8px;
}

.stats-matrix-box-container {
    grid-column: span 1;
    height: auto;
    border-radius: 10.8px;
    grid-row: span 1;
}

.box-1 {
    grid-row: span 2;
    border-radius: 10.8px;
}

.stats-average-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 1rem;
    row-gap: 1rem;
}

.stats-avg-box-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 1rem;
    border-radius: 12px;
    color: #E0E0E0;
    width: 95%;
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.avg-number {
    color: #EA80FC;
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
}

.stats-cube-type-select {
    width: 14vw;
}

.stats-option {
    width: 100%;
}

.stats-cube-type-select {
    border-radius: 5px;
    cursor: pointer;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    background: transparent;
}

.stats-cube-type-select option {
    padding: 0.1rem;
}

.MuiChartsLegend-label {
    fill: #FFFFFF !important;
    color: #FFFFFF;
}

@media screen and (max-width: 768px) {

    .stats-container {
        padding-bottom: 3rem;
    }

    .stats-heading {
        padding: 0rem 1rem;
    }

    .stats-btn {
        padding: 0rem 1rem;
    }

    .stats-content {
        flex-direction: column;
    }

    .stats-box-content {
        height: 90%;
    }

    .icon {
        margin-left: 0;
    }

    .stats-overview,
    .stats-event,
    .stats-event-box {
        width: 100%;
    }

    .stats-event{
        padding-bottom: 3rem;
    }

    .stats-content {
        padding: 1rem 1rem;
        margin-bottom: 2rem;
    }

    .stats-box-content-heading h1 {
        font-size: 1rem;
    }

    .stats-box,
    .stats-box-container {
        height: auto;
    }

    .stats-overview-content {
        grid-gap: 15px 10px;
    }

    .stats-average {
        width: 100%;
    }

    .stats-time-spent-content,
    .stats-total-solve-content {
        font-size: 30px;
    }

    .stats-event-box {
        padding: 0;
    }

}

@media screen and (max-width: 1250px) {
    .stats-box {
        height: auto;
    }

    .stats-event-box {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {

    .stats-box-content-heading h1 {
        font-size: 12px;
    }

}

.stats-no-solves-div {
    height: 150px;
    display: flex;
    align-items: center;
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px 10.96px 10.96px 0px;
    // background: rgba(255, 255, 255, 0.4);
  }
  
  #style-1::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px 10.96px 10.96px 0px;
    // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
    background: rgba(44, 44, 44, 0.1);
  
  }
  
  #style-1::-webkit-scrollbar-thumb {
    padding: 5px 0;
    border-radius: 0px 10.96px 10.96px 0px;
    background: rgba(0, 0, 0, 0.574);
  }
  
.help-container {
    width: 100%;
    // padding-bottom: 2vh;
    // max-height: 110%;
    overflow: auto;
}

iframe {
    border-radius: 12px;
}

.help-form-container{
    display: block;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-clear-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
}

.help-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    // color: #ffffff;
}

.help-box {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.help-video {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.help-video h1,
.help-contact-heading{
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 0px;
    letter-spacing: 0em;
    text-align: left;
    // color: #ffffff;

}

.help-video .video {
    width: 100%;
    border-radius: 12px;
}

.help-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.form-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px 40px;
    margin: auto;
}

@media screen and (max-width: 767px) {

    .help-form-container{
        display: none;
    }

    .form-content {
        grid-template-columns: 1fr; 
    }

    .help-container{
        padding: 0;
        margin-top: 5rem;
    }

    .help-heading{
        padding: 0rem 1rem;
    }

    .help-box{
        padding: 0rem 1rem;
    }

    .help-form{
        padding: 0;
    }

    .main-mobile{
        overflow-y: scroll;
        scroll-behavior: smooth;
        // padding-bottom: 2rem;
    }
}

.form-div {
    display: flex;
    flex-direction: column;
    row-gap: 0.1rem;
}

.form-div label {
    font-family: Nunito Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 1px;
    text-align: left;
    // color: #FFFFFFCC;
}

.form-div input, .form-div select{
    font-family: Nunito Sans;
    font-weight: 700;
    background: #FFFFFF8C;
    border: none;
    height: 30px;
    border-radius: 8px;
    font-size: 18px;
    padding: 0.2rem 0.5rem;
}

.form-div select{
    height: 35px;
}

.form-div input:focus{
    outline: none;
    height: 30px;
}

.form-div select:focus{
    outline: none;
    height: 35px;
}

.form-div textarea {
    border-radius: 12px;
    height: 70px;
    background: #FFFFFF8C;
    border: none;
    font-size: 18px;
    padding: 0.2rem 0.5rem;
}

.form-div textarea:focus{
    outline: none;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem 0rem;
}


.mobile-help-heading, .mobile-help-sub-heading{
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.mobile-help-sub-heading{
    font-size: 18px;
    opacity: 0.8;
}
.session-container {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
    .session-container{
        padding-bottom: 3rem;
        margin-top: 5rem;
    }
}

// .session-container::-webkit-scrollbar {
//     display: none;
// }

.session-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
// .session-active-bar{
//     height: 100px;
//     position: 'absolute';
//     width: 10px;
//     border: 2px solid red;
// }

@media screen and (max-width: 768px) {

    .session-heading {
        padding: 0rem 1rem;
    }

}

.session-matrix-select {
    display: flex;
    justify-content: flex-start;
}

// .session-matrix-select button {
//     cursor: pointer;
//     width: 110px;
//     height: 32px;
//     // padding: 5px 10px;
//     border-radius: 44.44px;
//     gap: 5px;
// }

@media screen and (max-width: 768px) {

    .session-matrix-select {
        padding: 0rem;
        position: relative;
        top: 0rem;
        justify-content: flex-start;
    }

}

.session-content {
    height: 65vh;
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 1rem;
    padding: 2rem 2rem;
}

@media screen and (max-width: 768px) {

    .session-content {
        padding: 1rem 1rem;
        align-items: center;
        flex-direction: column;
        height: auto;
    }

}

.session-content-left {
    // padding: 0.7rem;
    display: flex;
    flex-direction: column;
    // row-gap: 0.9rem;
    border-radius: 12px;
    // height: fit-content;
    height: 100%;
    overflow-y: scroll;
    width: 24%;
}


.session-content-left-mob {
    display: none;
}

.session-name-blocks {
    width: auto;
    border-radius: 11.79px;
}

.session-blocks {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // border: 2px solid red;
    // padding: 0 !important;
    // margin: 0;
    // padding-bottom: 0.5rem;
    padding-right: 5px;
}

.session-block-name {
    display: flex;
    justify-content: space-between;
}

.session-block-timeline {
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 600;
    // line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF8C;
}

.session-block-name-heading {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 700;
    // line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.session-block-name-btn,
.session-block-name-btn-current {
    color: #FFFFFFCC;
    cursor: pointer;
}

.session-block-name-btn-current {
    background: #C19BFD80;
    padding: 3px 8px;
    border-radius: 6px;
}

@media screen and (max-width: 768px) {

    .session-content-left {
        width: 90%;
        display: none;
    }

    .session-content-left-mob {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        bottom: 0rem;
        gap: 1rem;
        overflow: scroll;
        overflow-y: hidden;
        overflow-x: auto;
    }

    .session-content-left-mob::-webkit-scrollbar {
        display: none;
    }

    .session-blocks {
        border-bottom: none;
        display: block;
        padding: 0.6rem ;
        // height: 200px;
        border-radius: 11.79px;
        // width: 100%;
    }

    .session-block-name {
        gap: 7rem;
    }

    .session-block-name-heading {
        font-size: 18px;
        width: max-content;
    }
    
}

.session-content-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // row-gap: 1rem;
    width: 70%;
    height: 100%;
}

@media screen and (max-width: 768px) {

    .session-content-right {
        width: 100%;
    }

}

.session-right-content-top {
    display: flex;
    align-items: end;
    flex-direction: row;
    gap: 1rem;
}

@media screen and (max-width: 768px) {

    .session-right-content-top {
        flex-direction: column;
    }

}

.session-solves,
.session-stats,
.session-graph {
    display: flex;
    flex-direction: column;
    // row-gap: 0.5rem;
}

.session-graph{
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

@media screen and (max-width: 768px) {
    .session-graph{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.session-no-solves-div {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.session-solves,
.session-stats {
    width: 50%;
}

@media screen and (max-width: 768px) {

    .session-solves,
    .session-stats {
        width: 100%;
    }

}

.session-solve-heading,
.session-stats-heading,
.session-graph-heading {
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 700;
    // line-height: 35px;
    letter-spacing: 1px;
    text-align: left;
    color: #FFFFFF;
}

.session-stats-content {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: auto 1fr;
    // grid-gap: 10px 10px;
    border-radius: 12px;
    height: 47vh;
    // height: 300px;
}

.session-stats-content-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49%;
}

.session-stats-box {
    border-radius: 10.8px;
    padding: 0.5rem 1rem;
}

.session-stats-box-container {
    padding: 0.5rem 1rem;
    grid-column: span 1;
    height: 8.5vh;
    border-radius: 10.8px;
    grid-row: span 1;
}

.session-box-1 {
    grid-row: span 2;
    border-radius: 10.8px;
    height: 20vh;
}

.session-stats-box-content {
    // padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.session-stats-box-content-heading {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    // line-height: 0px;
    letter-spacing: 1px;
    text-align: left;
    color: #FFFFFF;
    text-transform: capitalize;
    cursor: default;
}

@media (min-width: 768px) and (max-width: 1000px) {

    .session-stats-box-content-heading {
        font-size: 12px;
    }

}

@media (max-width: 768px) {

    .session-box-1{
        height: 21vh;
    }

    .session-stats-box-container{
        height: 9vh;
    }

    .session-stats-box-content-heading {
        font-size: 13px;
    }

    .session-stats-box-content {
        height: 90%;
    }

}

.session-stats-pb-content,
.session-stats-dynamic-box-content,
.session-stats-worst-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Nunito Sans;
    font-size: 40px;
    font-weight: 700;
    // line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #00CC00;
    cursor: default;
}

.session-stats-pb-content {
    height: 100%;
}

.session-stats-dynamic-box-content {
    justify-content: end;
    font-size: 18px;
    color: #FFFFFF;
}

.session-stats-worst-content {
    justify-content: end;
    font-size: 18px;
    color: #FF0000;
}

.session-graph-box {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    margin-bottom: 5rem;
    // height: 240px;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {

    .session-graph-box {
        height: 170px;
    }

}

.solve-col {
    cursor: pointer;
    // font-size: 1.25rem !important;
}

.session-solve-box {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    row-gap: 0.5rem;
    border-radius: 12px;
    height: 47vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

@media (min-width: 768px) and (max-width: 1000px) {

    .session-solve-box {
        height: 260px;
    }

}

@media screen and (max-width: 768px) {
    .session-solve-box {
        height: 250px;
    }
}

.session-solve-box-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.session-solve-sno {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 400;
    min-width: 45px;
    // line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.session-solve-value {
    font-family: Nunito Sans;
    font-size: 18px;
    cursor: pointer;
    // text-decoration: underline;
    font-weight: 600;
    // line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #00CC00;
}

.session-solve-value:hover {
    text-decoration: underline;
}

.session-solve-box-right,
.session-solve-box-left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    gap: 0.5rem;
    width: 28%;
}

@media screen and (max-width: 768px) {
    .session-solve-box-right{
        width: 30%;
    }
    .session-solve-sno{
        min-width: 35px;
    }
}
.session-solve-box-left {
    column-gap: 1rem;
}

.session-solve-increment,
.session-solve-content {
    font-family: Nunito Sans;
    font-size: 16px;
    // font-weight: 500;
    // line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF8C;
}

.session-solve-del-box {
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 600;
    // line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF0000;
    padding: 0.3rem;
    border: 0.88px solid #FF0000;
}

.session-no-solves-div h1 {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 700;
    // line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.stats-cube-type-select-session {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: none;
    //   padding-right: 24px; 
}

.custom-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 1.1rem;
    padding-right: 0.5rem;
    transform: translateY(-50%);
}

canvas{
    width: 100% !important;
    height: 100% !important;
}

.session-other{
    // padding-top: 15px;
    // padding-bottom: 15px;
    width: 100%;

    // border: 2px solid green
}
.session-active-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid red;
    // margin-left: 15px;   
    height: 10vh;
}
.session-active{
    margin-left: -5px;
    border-radius: 10px;

}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px 10.96px 10.96px 0px;
    // background: rgba(255, 255, 255, 0.4);
  }
  
  #style-1::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px 10.96px 10.96px 0px;
    // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
    background: rgba(44, 44, 44, 0.1);
  
  }
  
  #style-1::-webkit-scrollbar-thumb {
    padding: 5px 0;
    border-radius: 0px 10.96px 10.96px 0px;
    background: rgba(0, 0, 0, 0.574);
  }
  
@import "../../../assets/styles/variables";

.timer-bottom-bar {
    // height: 38vh;
    // border: 2px solid red;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 9vh;
}

.hr-line {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    user-select: none;
}

.timer-box {
    // cursor: pointer;
    height: 38vh;
    width: 25vw;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px 10.96px 10.96px 0px;
    // background: rgba(255, 255, 255, 0.4);
}

::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px 10.96px 10.96px 0px;
    // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
    background: rgba(255, 255, 255, 0.1);

}

::-webkit-scrollbar-thumb {
    padding: 5px 0;
    border-radius: 0px 10.96px 10.96px 0px;
    background: rgba(255, 255, 255, 0.4);
}

.timer-box-2 {
    display: flex;
}

.timer-box-3 {
    display: flex;
    // padding: -150px -20px;
    // overflow-y: scroll;
}

.timer-column {
    height: 95%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    // border: 2px solid red;
}

.innerBox {
    width: 11.4vw;
    height: 8vh;
    border-radius: 12px;
}

@media screen and (max-width: 767px) {
    .innerBox {
        height: 7vh;
    }

}

.pb {
    height: 16vh;
}

.timer-text {
    margin-top: 3%;
    margin-left: 8%;
    font-size: 0.9rem;
    text-transform: capitalize;
    font-family: 'Nunito Sans';
    font-weight: 700;
    cursor: default;
    // border: 2px solid red;
}

.timer-value {
    display: flex;
    justify-content: flex-end;
    margin-right: 12%;
    font-size: 1rem;
    font-family: 'Nunito Sans';
    cursor: default;
    font-weight: 700;
}

.pb-value {
    height: 10vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00cc00;
    font-size: 2rem;
    font-family: 'Nunito Sans';
    font-weight: 700;
}

.worst-value {
    color: red;
}

.timer-box-select {
    color: white;
    position: absolute;
    z-index: 100;
    border-radius: 12px;
    width: 5vw;
    height: 4vh;
    margin-bottom: 32vh;
    padding: 5px 4px;
    margin-right: 19vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 1);
    outline: none;
    cursor: pointer;
    text-transform: capitalize;
    gap:5px
    
}

.timer-box-1 {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: 0;
}

.timer-box-1 ::-webkit-scrollbar {
    display: none;
}

.timer-plus2,
.timer-dnf {
    font-size: 17px;
    font-family: 'Nunito Sans';
    font-weight: 700;
}

.timer-solve {
    padding-top: 1.5vh;
    display: flex;
    align-items: center;
    width: 100%;
    // border: 2px solid red;
    justify-content: space-between;
}

.timer-solve-left {
    // width: 20%;
    display: flex;
    // column-gap: 1rem;
    // border: 2px solid red;
    justify-content: space-between;
    margin-left: 5%;
}

.timer-solve-right {
    margin-right: 5%;
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
}

.solve-value {
    color: #00cc00;
    font-size: 18px;
    font-family: 'Nunito Sans';
    font-weight: 1000;
    width: 2rem;
}

.solve-value:hover {
    text-decoration: underline;
    // border-bottom: 2px solid ;
}

.sno {
    font-size: 18px;
    font-family: 'Nunito Sans';
    font-weight: 700;
    min-width: 45px;
}

.timer-solve-delete {
    color: red;
    border: 1px solid red;
    padding: 0px 3px;
    font-family: "Nunito Sans", sans-serif;
}

.no-solves-div {
    // border: 2px solid red;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plus2-enable {
    color: red !important;
}

.plus2DNF-enable {
    color: red !important;
    font-weight: 700;
}

@media screen and (max-width: 767px) {

    // changed for mobile
    .timer-bottom-bar {
        // border: 2px solid red;
        height: auto;
        justify-content: center;
        align-items: flex-end;
        // cancel margin if needed for megaminx
        margin-top: 0;
    }

    // changed for mobile
    .appear {
        // border: 2px solid red;
        width: 95vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    // changed for mobile
    .timer-box {
        width: 90vw;
        height: 37vh;
        position: relative;
        left: 0rem;
        top: 0rem;
    }
}

@media only screen and (max-width: $mobile-screen-width-timer-max) {

    .disappear {
        display: none;
        height: 0px !important;
    }

    .timer-column {
        // justify-content: center;
        // gap: 1rem;
        justify-content: end;
        gap: 0.5rem;
    }

    .timer-box-3,
    .timer-box-1 {
        height: 70% !important;
    }

    .timer-box-1 {
        height: 80% !important;
        top: 1rem !important;
        align-items: self-start;
    }

    .innerBox {
        width: 40vw;
        // height: 6vh;

    }

    .pb {
        height: 15vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .pb-value {
        height: 7vh;

    }

    .show-footer {
        width: 8vw;
        aspect-ratio: 22/3;
        border-radius: 50px;
        display: flex;
        padding: 0.25rem;
        justify-content: center;
        align-items: center;
        // margin-bottom: 50px;
        // border: 2px solid red;
    }

    .show-footer-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

    }

    .footer-down-arrow {
        width: 5vw;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    // .KeyboardArrowDownIcon {
    //     padding-top: 5px;
    // }

    .timer-box-select {
        width: 30vw;
        margin-right: 50vw;
        // margin-top: -8vh;
        margin-top: 0;
    }

    .hide-footer-mobile {
        // border: 2px solid red;
        color: white;
        position: absolute;
        z-index: 1000;
        border-radius: 12px;
        width: 9vw;
        height: 4vh;
        // margin-top: -40vh;
        margin-top: -32vh;
        margin-right: -75vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(51, 51, 51, 1);
        outline: none;
        cursor: pointer;
        font-size: 3.25vw;
        padding: 5px 0px;
    }

        .show-footer-mobile {
        // border: 2px solid red;
        color: white;
        // position: absolute;
        z-index: 1000;
        border-radius: 12px;
        height: 30px;
        width: 35px;
        // // margin-top: -40vh;
        // margin-top: -32vh;
        // margin-right: -75vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(51, 51, 51, 1);
        outline: none;
        cursor: pointer;
        font-size: 3.25vw;
        padding: 5px 0px;
    }

    .solve-col {
        margin: 0 5px;
    }

    .timer-solve {
        width: 73vw;
        // justify-content: space-between;
        // border: 2px solid red;
    }

    .timer-solve-left {
        margin-left: 1.25rem;
        width: 20vw;
        // border: 2px solid red;

    }

    .timer-solve-right {
        width: 20vw;
        margin: 0;
        // border: 2px solid red;

    }
    .overlay-css{
        // border: 2px solid red;
        z-index: 1002;
        position: absolute;
        top: 55%;
        left: 5%;
 
    }
}


#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px 10.96px 10.96px 0px;
    // background: rgba(255, 255, 255, 0.4);
}

#style-1::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px 10.96px 10.96px 0px;
    // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
    background: rgba(44, 44, 44, 0.1);

}

#style-1::-webkit-scrollbar-thumb {
    padding: 5px 0;
    border-radius: 0px 10.96px 10.96px 0px;
    background: rgba(0, 0, 0, 0.574);
}


  
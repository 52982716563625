@import "./mixins";
@import "./variables";

.input-container {
  padding-left: 10px;
  display: flex;
  width: 20vw;
  min-width: 230px;
  max-width: 500px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(217, 217, 217, 1);
  margin-top: 2%;
  aspect-ratio: 19/3;
  // border: 2px solid red;
}

.input {
  border-radius: 5px;
  max-height: 80px;
  min-height: 40px;
  min-width: 150px;
  border: none;
  width: 17vw;
  aspect-ratio: 19/4;
  // border: 2px solid red;
  // margin-left: 10px;

  background-color: rgba(217, 217, 217, 1);
  &:focus {
    outline: none;
    border-color: transparent;
    background-color: rgba(217, 217, 217, 1);
  }
  &::placeholder {
    transition: opacity 0.2s ease;
  }

  &:focus::placeholder {
    opacity: 0;
  }

  &.filled::placeholder {
    opacity: 0;
  }
}
.icon {
  margin-left: 5px;
  position: relative;
}

.auth-email-container {
  @include flexcenter();
  flex-direction: column;
  width: 100%;
}

.auth-email-button-container {
  @include flexcenter();
  width: 100%;
}

.auth-float {
  margin: 25px 0 25px 0;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;

  button {
    cursor: pointer;
    @include themify($themes) {
      background-color: themed("backgroundColor");
      color: themed("buttonTextColorPrimary");
    }
    width: 10vw;
    aspect-ratio: 14/4;
    min-width: 150px;
    height: auto;
    border: none;
    padding: 15px 23px 15px 23px;
    border-radius: 8px;

    &:hover {
      @include themify($themes) {
        filter: drop-shadow(0 4 8 themed("borderColor_0"));
        box-shadow: 0px 4px 8px 0px themed("borderColor_0");
        background-color: themed("backgroundColor");
        opacity: 0.8;
      }
    }
  }
}

Link{
  text-decoration: none;
}

input{
  outline: none;
}

option{
  cursor: pointer;
}

option:hover {
  background-color: none;
}
// ::selection, select:focus::-ms-value {
//   background-color: none;
//   color: #000;
// }

// option:checked {
//   background-color: none;
//   color: #000;
// }

// option:checked:hover, select:focus option:checked:hover {
//   background-color: none;
//   color: #000;
// }
.solves-container {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
    .solves-container{
        padding-bottom: 3rem;
        margin-top: 5rem;
    }
  
}

.pagination-button {
    padding: 6px 15px;
}

.solves-container::-webkit-scrollbar {
    display: none;
}

.solves-header {
    display: flex;
    flex-direction: column;
}

.solves-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    // color: #ffffff;
}

.solves-btn {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1rem;
}

.solves-btn-button {
    cursor: pointer;
    width: 95px;
    height: 32px;
    // padding: 5px 10px;
    border-radius: 44.44px;
    gap: 5px;
}

.solves-export-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 15px;
    font-size: 0.9rem;
    border-radius: 3px;
    cursor: pointer;
    border: none;
    color: white;
    column-gap: 0.3rem;
    align-items: center;
    background-color: #692bcb;
}

.no-solves-conatiner {
    padding: 0 2rem;
    border-radius: 10px;
}

.no-solves-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 330px;
    width: 100%;
    border-radius: 10px;
    // background: #0A003A33;
}

.no-solves-div p {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.solves-content {
    margin-top: 2rem;
    padding: 0 2rem;
}

.solves-content-container {
    display: flex;
    flex-wrap: wrap;
    // padding: 0rem 2rem;
    gap: 1rem;
}

.solves-content h1 {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    // line-height: 22px;
    // padding: 0rem 2rem;
    letter-spacing: 1px;
    text-align: left;
    // color: #FFFFFF;
}

.solves-content-box {
    display: flex;
    column-gap: 0.5rem;
    justify-content: space-between;
    flex: 0 1 calc(33.33% - 60px);
    padding: 15px;
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
}

@media (max-width: 768px) {

    .solves-btn,
    .solves-heading {
        padding: 0 1rem;
    }

    .solves-content {
        padding: 1rem;
        padding-bottom: 3rem;
        margin-top: 0;
        gap: 1.5rem;
    }

    .solves-content h1,
    .no-solves-conatiner,
    .solves-content-container {
        padding: 0;
    }

    .solves-content-container {
        gap: 0.5rem;
    }

    .solves-content-box {
        flex: 0 1 calc(50% - 45px);
    }
}

/* Media query for even smaller screens */
@media (max-width: 640px) {
    .solves-content-box {
        flex: 1 1 100%;
    }
}

.solves-content-box-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.solves-content-box-right {
    display: flex;
    justify-content: start;
    align-items: start;
}

.solves-content-box-label {
    font-family: Nunito Sans;
    font-size: 30px;
    font-weight: 700;
    // line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    // color: #FFFFFF;
}

.solves-content-box-buttons {
    display: flex;
    column-gap: 0.5rem;
}

.solves-content-box-buttons-matrix {
    cursor: pointer;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    // background: #AA4FF6;
    padding: 2px 7px 2px 7px;
    border-radius: 4px;
}

.solves-content-box-buttons-fold {
    cursor: pointer;
    padding: 2px 7px 2px 7px;
    color: #FFFFFF;
    // background: #AA4FF6;
    border-radius: 4px;
}

.solves-content-box-solve-id {
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    // color: #FFFFFF;
}

.solves-pagination-controls {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;
}

// .solves-pagination-controls button {
//     padding: 5px 10px 5px 10px;
//     border-radius: 40px;
//     // background: #FFFFFF;
//     // color: #0A003A;
//     font-family: Nunito Sans;
//     font-size: 18px;
//     font-weight: 600;
//     line-height: 27px;
//     letter-spacing: 0em;
//     text-align: left;
//     cursor: pointer;
// }

.solves-pagination-controls p {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.solves-pagination-controls button:disabled{
    background: #FFFFFF33;
    color: #0A003A80;
}

@media screen and (max-width:768px) {
    .solves-pagination-controls{
        column-gap: 0.5rem;
        padding-bottom: 3rem;
    }

    .solves-pagination-controls button {
        // padding: 2px 8px 2px 8px;
        font-size: 15px
    }
}

.all-solves-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
}

@media screen and (max-width:768px) {
    .all-solves-container {
        // height: 65vh;
        height: auto;
    }
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px 10.96px 10.96px 0px;
    // background: rgba(255, 255, 255, 0.4);
  }
  
  #style-1::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px 10.96px 10.96px 0px;
    // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
    background: rgba(44, 44, 44, 0.1);
  
  }
  
  #style-1::-webkit-scrollbar-thumb {
    padding: 5px 0;
    border-radius: 0px 10.96px 10.96px 0px;
    background: rgba(0, 0, 0, 0.574);
  }
  
// .driver-popover.driverjs-theme {
// width: 700px;
// }

.my-custom-popover-class{
  margin-left: 7vw;
  width: 300vw;
}
  
  .driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
  }
  
  .driver-popover.driverjs-theme .driver-popover-title,
  .driver-popover.driverjs-theme .driver-popover-description,
  .driver-popover.driverjs-theme .driver-popover-progress-text {
    // font-size: larger;
  }
  
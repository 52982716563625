@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.setting-container {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
    .setting-container{
        padding-bottom: 3rem;
        margin-top: 5rem;
    }
    
}

.dropdown-color{
    color: black !important;
}
.setting-header {
    display: flex;
    flex-direction: column;
}

.setting-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.setting-btn {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.setting-btn button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    height: 32px;
    padding: 0px 15px;
    border-radius: 44.44px;
    gap: 5px;
}

@media (max-width: 768px) {
    
    .setting-button{
        // border: 2px solid red;
        width: 23vw;
    }
    .setting-btn,
    .setting-heading {
        padding: 0 1rem;
    }

    .setting-btn {
        column-gap: 0.4rem;
    }
}

.setting-content {
    // padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    justify-content: flex-start;
    width: 63%;
}

.setting-update-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    // width: 50%;
}

.setting-update-btn button {
    cursor: pointer;
    padding: 8px 24px;
    border-radius: 5px;
    border: none;
    gap: 5px;
    font-size: 15px;
    font-weight: 600;
}

.setting-update-btn button:hover {
    background: #00cc00;
}

.setting-box {
    display: flex;
    justify-content: space-between;
    padding: 0.7rem;
    border-radius: 12px;
    column-gap: 5rem;
    // width: 50%;
}

.setting-box-text {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.setting-box-btn {
    width: 75px;
    height: 35px;
    border-radius: 30px;
    position: relative;
}

.setting-delete-btn {
    cursor: pointer;
    padding: 3px 10px;
    background: rgba(255, 0, 0, 1);
    border-radius: 5px;
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .setting-box {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .setting-box {
        width: 95%;
        padding: 0.5rem;
        column-gap: 0;
        // border-bottom: 0.5px solid;
        // border-radius: 0px;
    }

    .setting-content {
        // padding: 2rem 1rem;
    }

    // .setting-box-text {
    //     font-size: 15px;
    // }

    .setting-box-btn {
        width: 100px;
    }
}


.setting-toggle-btn {
    position: absolute;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2px;
    width: 45px;
    height: 110%;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;;
    background: white;
}

.left-zero{
    left: 0px;
}

.right-zero{
    right: 0px;
}

.setting-toggle-btn label {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    cursor: pointer;
}

.setting-toggle-btn input {
    position: absolute;
    display: none;
}

.slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: 0.3s;
    background: linear-gradient(154.84deg, #E817BA -20.36%, rgba(232, 23, 186, 0) 51.7%, rgba(232, 23, 186, 0) 86%),
        linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
}

.setting-toggle-btn input:checked~.slider {
    background: #0A003A;
}

.slider::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 4px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #E817BA;
    transition: 0.3s;
}

.setting-toggle-btn input:checked~.slider::before {
    transform: translateX(40px);
}

.setting-display-btn-box {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.setting-theme-btn,
.setting-theme-btn-active {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
}


.setting-theme-btn-active {
    background: #692BCB;
    color: #FFFFFF;
}

.setting-theme-btn:hover {
    background: #00cc00;
}

.setting-content-box,
.setting-display-box {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 1rem;
    width: 100%;
    border-radius: 12px;
}

.setting-display-box {
    width: 96%;
}

.setting-content-box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.setting-content-box-top-heading,
.setting-display-box-top-heading {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 700;
    // line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.setting-content-box-text,
.setting-display-box-text {
    font-family: Nunito Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    // color: #FFFFFF8C;
    width: 80%;
}
.setting-display-box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .setting-content-box {
        width: 75%;
    }

    .setting-display-box {
        width: 88%;
    }

    .setting-display-box-top, .setting-last-box {
        flex-direction: column;
        row-gap: 1rem;
    }
}

@media screen and (max-width: 768px) {

    .setting-content-box,
    .setting-display-box {
        row-gap: 0.5rem;
        width: 95%;
        padding: 0.5rem;
    }

    .setting-content-box-text,
    .setting-display-box-text {
        font-size: 15px;
        width: 100%;
    }

    // .setting-content-box-top-heading,
    // .setting-display-box-top-heading {
    //     font-size: 20px;
    // }

    .setting-theme-btn {
        font-size: 15px;
    }
}

.setting-display-box-top-left {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.setting-display-box-timer-text {
    font-family: "Graduate", sans-serif;
    font-size: 60px;
    text-align: center;
    font-weight: 400;
    // line-height: 95px;
    letter-spacing: 0em;
    color: #FFFFFF;
}

.setting-display-box {
    row-gap: 1rem;
}

.setting-display-font-select {
    position: relative;
    width: 205px;
    height: 5px;
    border-radius: 20px;
    background: #555555;
}

.circle-dots {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: -8px;
    column-gap: 1.5rem;
}

.circle-dots span {
    width: 12px;
    height: 12px;
    border: 5px solid;
    border-radius: 50%;
    background: rgb(255, 255, 255);
}

.circle-dots .selected-default {
    background: #662AC6;
}

.circle-dots .selected-dark, .circle-dots .selected-dark {
    background: rgb(51, 51, 51);
}



.setting-display-font-select span {
    font-family: Nunito Sans;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .setting-display-font-select {
        width: 230px;
    }

    .circle-dots {
        column-gap: 2rem;
    }

    .setting-display-box-top {
        flex-direction: column;
        row-gap: 2rem;
        align-items: self-start;
    }
    .setting-content-box-text{
        line-height: 1;
    }

    .setting-display-font-select{
        margin: auto;
    }

    .setting-display-box-top-left {
        width: 100%;
    }    

    .setting-update-btn {
        justify-content: center;
    }
}

.setting-last-box {
    display: flex;
    justify-content: end;
    align-items: center;
}

.setting-web {
    display: flex;
}

.setting-mobile {
    display: none;
}

.setting-heading-inner {
    font-family: Nunito Sans;
    font-size: 35px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .setting-last-box {
        flex-direction: column;
        row-gap: 1rem;
    }

    .setting-content {
        row-gap: 0.5rem;
        padding: 0.5rem 0rem;
    }

    .setting-btn {
        display: none;
    }

    .setting-web {
        display: none;
    }

    .setting-mobile {
        display: flex;
        width: 100%;
    }

}

.settings-main-content{
    display: flex;
}

.settings-buttons-container {
    display: flex;
    padding: 0rem 2rem;
}

.settings-buttons-container-mobile{
    display: none;
}

.settings-btn-inner-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px ;
   height: fit-content;
}

@media screen and (max-width: 768px) {
    .settings-buttons-container {
        display: none;
    }

    .settings-buttons-container-mobile{
        display: flex;
        width: 100%;
        padding: 0rem 1rem;
        padding-bottom: 5rem;
    }

    .settings-btn-inner-container{
        width: 100%;
    }
    
}


.settings-btn-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 1rem 0.7rem 1rem;
    cursor: pointer;
    gap: 10px;
    border-radius: 10px 10px 0 0;
}

.settings-btn-icon {
    width: 24px;
    height: 24px;
    color: #FFFFFF;
}

.settings-btn-left {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.settings-btn-arrow{
    color: #FFFFFF;
}
.settings-btn-text {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.active-default-timer{
    background: #662AC6;
    border-radius: 10px 10px 0 0;
}

.active-default-display{
    background: #662AC6;
    border-radius: 0;

}

.active-default-other{
    background: #662AC6;
    border-radius: 0 0 10px 10px;
}

.active-dark{
    background: rgba(51, 51, 51, 1);
} 

.active-light{
    background: rgba(51, 51, 51, 1);
}

.color-default{
    background: rgba(10, 0, 58, 1);
}

.color-dark{
    background: rgba(33, 33, 33, 1);
}

.color-light{
    background: rgba(222, 222, 222, 1);
}

@media (min-width: 768px) and (max-width: 1024px) {
    .setting-display-box-top-left{
        width: auto ;
    }

    .setting-last-box{
        align-items: flex-start;
    }
}

.import-dropdown{
    position: absolute;
    top: 8vh;
    width: 12vw;
    padding: 0.7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    border-radius: 8px;
}

.import-dropdown div{
    font-family: Nunito Sans;
    font-size: 1em;
    font-weight: 700;

}

@media screen and (max-width: 768px) {
    .import-dropdown{
        width: 40vw;
        padding: 1vw;
        right: -5vw;
        top: 5vh;
    }
}
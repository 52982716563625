@import "../../../assets/styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.timer-section{
   
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 2px solid red;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    -webkit-touch-callout: none;
  }

.timer-scramble-text{
    font-size: 1.2vw;
    margin: 0 70px;
    // font-family: 'Nunito Sans', sans-serif;
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.scramble-icons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 10vw;
    margin-top: 4vh;
    // border: 2px solid red;
    .lock-img{
        cursor: pointer;
        width: 1.3vw;
        padding: 2px;
        color: #fff;
        &.lock {
           background-color:#0A003A;
           border-radius: 50px;
          }
    }
    .lock-img:active{
        transform: translateY(2px);
    }
}

// changed for mobile
@media screen and (max-width: 768px) {

    .timer-section{
        height: auto;
    }

    .scramble-icons{
        padding: 1rem;
        margin-top: 2vh;
    }

    .lock-img{
        width: 1rem !important;
    }
}

// @font-face {
//     font-family: 'digital-clock-font';
//     src: url('~assets/fonts/timer/digital-7.ttf'); // Use ~ to resolve from src directory
//   }

// changed for mobile
.timer{
    font-size: 8vw;
    font-family: 'Graduate', sans-serif;
    margin-top: 1rem;
    // border: 2px solid red;
          padding: 2rem;
}

.timer-input-manual{
    font-family: "Graduate", sans-serif;
    height: 100%;
    width: 100%;
    font-size: 40px;
    text-align: center;
    color: white;
    letter-spacing: 2px;
    border: 2px solid white;
    background-color: rgba(10, 0, 58, 1);
}

@media only screen and (max-width: $mobile-screen-width-timer-max) {

    .timer-input-manual{
        width: 70%;
    }

    .timer-scramble-text{
        font-family: "Ubuntu Mono", monospace;
        width: 85vw;
        font-size: 1rem;
    }
    .timer-scramble-text-444{
        font-family: "Ubuntu Mono", monospace;
        width: 85vw;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .timer-scramble-text-555{
        font-family: "Ubuntu Mono", monospace;
        width: 85vw;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .timer-scramble-text-666{
        font-family: "Ubuntu Mono", monospace;
        width: 85vw;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .timer-scramble-text-777{
        font-family: "Ubuntu Mono", monospace;
        width: 85vw;
        font-size: 0.8rem;
        font-weight: bold;
    }
    .timer-scramble-text-minx{
        font-family: "Ubuntu Mono", monospace;
        width: 85vw;
        font-size: 0.6rem;
        font-weight: bold;
    }
    .scramble-icons{
        
            // border: 2px solid red;
            width: 40vw;
            img{
                width: 1rem;
            }
    }

    // changed for mobile
    .timer{
        font-size: 20vw;
        margin-top: 0;
          padding: 1rem;
        font-family: 'Graduate', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .timer-section{
        position: fixed;
        top: 30%;
    }
    .top-touch{
        // border: 2px solid red;
        // height: 100vh;
        width: 100vw;
        position: absolute;
        // top: 15%;
        user-select: none;
        -webkit-touch-callout : none;
        -webkit-user-select:none
    
    }
    .bot-touch{
        // border: 2px solid red;
        height: 25%;
        width: 100vw;
        position: absolute;
        bottom: 22%;
        user-select: none;
        -webkit-touch-callout : none;
        -webkit-user-select:none
    }
    .copy-toast{
        width: 50%;
        font-size: small;
    }
}

.red-text{
    color: orangered;
}

.green-text{
    color: greenyellow;
}

.timer-solve-text{
    z-index: 10005;
    position: relative;
}

.minx-conatiner{
    display: flex;
    gap: 0.75rem;
}

@media screen and (max-width: 768px){
    .minx-conatiner{
        gap: 0rem;
        flex-direction: column;
    }
}

.timer-started {
    opacity: 0.3;
}
.side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  /* padding: -100px 0; */
  /* min-width: 200px; */
  /* border: 2px solid yellow; */
}
/* .top {
  margin-bottom: 2px;
}
.bottom {
  margin-top: 5px;
} */
.face {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  width: 25%;
  height: 75%;
  margin-left: 1.5%;
  margin-right: 1.5%;
}

.front {
  justify-content: space-between;
}
.back {
  margin: 0;
}
.right {
  margin-left: 0;
}
.left {
  margin-right: 0;
}
.row {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.box {
  height: 32%;
  width: 100%;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1/1;
}

.cell {
  width: 90%;
  /* border: 2px solid red; */
  /* height: 100%; */
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 2px;
  aspect-ratio: 1/1;
}

.w {
  /* border: 2px solid red; */
  background-color: white;
}

.o {
  background-color: #ff9826;
}

.r {
  background-color: #ff4343;
}

.g {
  background-color: #43ff43;
}

.b {
  background-color: #246bfd;
}

.y {
  background-color: yellow;
}

.svg-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
}
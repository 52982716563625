@import "../../../assets/styles/variables";

.select-container {
  position: relative;
  font-family: sans-serif;
}

.select {
  border-radius: 50px;
  width: 8vw;
  // border: 1px solid #ccc;
  // margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 0.8rem;
  font-family: "Nunito Sans";
  font-weight: 700;
}

.selected-option {
  flex-grow: 1;
}

.selected {
  background-color: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: 3px 3px 0 3px;
}

.arrow.down {

  border-top: 5px solid white;
}

.arrow.up {
  border-bottom: 5px solid white;
}

.dropdown {
  position: absolute;
  top: 1.9rem;
  left: -5px;
  right: 0px;
  border-radius: 10px;
  height: 30vh;
  max-height: 25vh;
  overflow-y: auto;
  width: 140px;
  z-index: 1000;
  margin-top: 3px;
}

//   .dropdown::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	background-color: #F5F5F5;
//   border-radius: 5px;
// }

// .dropdown::-webkit-scrollbar
// {
// 	width: 6px;
//   border-radius: 5px;
// 	background-color: #F5F5F5;
// }

// .dropdown::-webkit-scrollbar-thumb
// {
// 	background-color: #E817BA;
//   border-radius: 5px;
// }

.options {
  border-bottom: 10.44px;
  border-top: none;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.option {
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  color: white;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  // padding: 10px 8px 10px 8px;
  // cursor: pointer;
  // color: white;
  // font-size: 1em;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // width: 100%;
  font-family: "Nunito Sans";
  font-weight: 700;
}

.selected-default {
  color: #9c71e1;
}

.selected-dark {
  color: rgb(141, 128, 128);
}

.selected-light {
  color: rgb(144, 144, 144);
}

.font-black {
  color: #000
}

// .option:hover {
//   // background-color: #f0f0f0;
// }

// .option.selected {
//   // background-color: #007bff;
//   // color: #fff;
// }

.DD-in {
  padding: 10px 5px;
  font-size: 0.7rem;
  border-radius: 3px;
  border: none;
  /* font-family: "Nunito Sans"; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}

.CNS-drop-create-button {
  padding-top: 10px;
  // height: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: none;
  border-top-left-radius: 10.44px;
}



@media only screen and (max-width: $mobile-screen-width-timer-max) {
  .select {
    width: 110px;
  }

  .select-container{
    width: 140px;
  }

  .DD-in {
    padding: 10px;
    font-size: 0.8rem;
  }
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px 10.96px 10.96px 0px;
  // background: rgba(255, 255, 255, 0.4);
}

#style-1::-webkit-scrollbar {
  width: 7px;
  border-radius: 0px 10.96px 10.96px 0px;
  // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
  background: rgba(44, 44, 44, 0.1);

}

#style-1::-webkit-scrollbar-thumb {
  padding: 5px 0;
  border-radius: 0px 10.96px 10.96px 0px;
  background: rgba(0, 0, 0, 0.574);
}
@import "../../../assets/styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.auth-main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  
}

.auth-image {
  width: 70%;
}

.auth-center {
  position: relative;
  width: 31.5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.auth-back {
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  left: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0;
  margin-top: 10%;
}
.Success-mess{
  margin-top:15px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

@media screen and (max-width: 768px){
  .auth-back{
    top: 3.5rem;
    left: -0.5rem;
  }
  .auth-main {
    // height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow-y: auto;
    
  }
  
}

.auth-back-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: #fff;
  font-size: 1rem;
  height: auto;
  background: none;
  // margin-top: 10px;
}

.auth-input {
  // width: 20vw;
  padding: 5px 1rem;
  aspect-ratio: 22/3;
  // border-radius: 8px;
  display: block;
  width: 15vw;
  // padding: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #c8c8c8;
  border-radius: 3px;
  min-height: 24px;
  line-height: 24px;
  background-color: #fff;
  color: #424242;
  transition: box-shadow 1ms cubic-bezier(1, 0, 0, 1);
  -webkit-appearance: none;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
}

.auth-label {
  font-family: Nunito Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 1px;
  text-align: left;
  width: 100%;
  color: white;
}

.auth-button {
  color: white;
  padding: 0 20px;
  line-height: 1.6;
  min-width: 44px;
  // height: 44px;
  border: none;
  width: 17vw;
  // aspect-ratio: 22/3;
  border-radius: 3px;
  font-size: 1.4vw;
  cursor: pointer;
  height: auto;
  aspect-ratio: 20/3.5
}

.auth-google {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  aspect-ratio: 22/3;
  background-color: transparent;
  width: 100%;
  // max-width: 400px;
  // min-width: min-content;
  cursor: pointer;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  // height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  gap: 0.5rem;

  img {
    width: 1.5vw;
    aspect-ratio: 1;
  }
}

.auth-google-text {
  font-size: 16px;
  font-weight: 500;
  font-family: "Open Sans", arial, sans-serif;
  text-overflow: ellipsis;
}

.auth-google-stack {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // height: 15vh;
  gap: 0.5rem;
}

.auth-logo {

  // min-width: px;
  img {
    width: 12vw;
    min-width: 150px;
  }
}

.auth-heading {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.2vw;
  font-weight: 600;
}

.auth-input-stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: fit-content;
}

.auth-input-button-stack {
  // border: 2px solid blue;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 25vh;
  // width: 17vw;
}

.auth-input-label {
  font-size: 1vw;
}

.auth-bottom-stack {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 45vh;
}

// .input-error-div{
//   width:17vw;
// }

@media only screen and (max-width: $mobile-screen-width-max) {
  .auth-heading {
    font-size: 1.5rem;
  }

  .auth-center {
    width: 70% !important; 
    height: 100%;
    gap: 0;
  }

  .auth-google {
    // border: 2px solid red;
    border: 2px solid red;
    // min-width: 100px;
    // width: 14vw;
    // height: 5vh;

    // aspect-ratio: 20/2;
    img {
      width: 4vw;
      min-width: 30px;

    }
  }

  .auth-google-text {
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
  }

  .auth-input {
    margin-top: 10px;
    width: 15rem;
    aspect-ratio: 20/3.5;
  }

  .auth-input-label {
    font-size: 0.8rem;
  }

  .auth-button {
    width: 17rem;
    aspect-ratio: 20/3.5;
    font-size: 1rem;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .auth-image {
    display: none;
  }

  .auth-input{
    padding: 0 1rem;
  }

  .auth-button{
    width: 17rem;
    height: auto;
  }

}

.auth-bottom-text{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 1000;
  font-size: 1.5em;
  width: 100%;
}
@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.signup-right {
  z-index: 100;
  margin: 0;
  padding: 0;
  height: 100vh;
  flex: 5;

  .right-width {
    position: relative;
    width: 100%;
    .up-arrow-container-right {
      position: absolute;
      width: 100%;
      z-index: 100;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      img {
        height: 80%;
      }
    }
  }
}

.signup-right-container {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.signup-right-bottom {
  @include flexcenter();
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex: 5;
}

.signup-logo-container {
  border: 2px solid red;
  // width: 100%;
  flex: 1;
  display: flex;
  align-items: center;

  img {
    width: 20%;
    margin-left: 5%;
    min-width: 150px;
  }
}
.signup-fields-container {
  width: 100%;
  @include flexcenter();
  flex-direction: column;
}

.input-two-fit {
  display: flex;
  margin-top: 1.5vh;
  width: 20vw;
  min-width: 230px;
  justify-content: space-around;
  align-items: center;
}
.fit {
  width: fit-content !important;
  aspect-ratio: 14/5 !important;
  min-width: 70px !important;
  margin-right: 2%;
  margin-left: 2%;
  .fit-input {
    // border: 2px solid red;
    min-width: 60px !important;
    aspect-ratio: 14/5 !important;
    width: 5vw;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

@media only screen and (max-width: $mobile-screen-width-max) {
  .signup-logo-container {
    justify-content: center;
  }
}

.solve-modal-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0.5rem;
    // margin-bottom: 2rem;
    position: relative;
    z-index: 500;
}

.solve-modal-share-link,
.solve-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 9px;
    font-size: 1rem;
    border-radius: 13px;
    cursor: pointer;
}

.solve-modal-btn {
    font-weight: 400;
    margin-right: 2rem;
    cursor: pointer;
}

.solve-modal-box {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 3rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #957d7d;
    margin-bottom: 2rem;
}

.solve-modal-timer {
    font-family: "Graduate", sans-serif;
    font-size: 50px;
    letter-spacing: 0.35rem;
}

.solve-modal-date,
.modal-solve-methods {
    position: relative;
    bottom: 2rem;
}

.solve-modal-timer{
    position: relative;
    top: 1rem;
}

.solve-modal-date {
    font-size: 1rem;
    font-weight: 400;
    font-family: Nunito Sans;
}

.modal-solve-methods {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.modal-solve-cubetype,
.modal-solve-plus2,
.modal-solve-dnf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 12px;
    font-size: 1rem;
    border-radius: 13px;
    // font-weight: 500;
    cursor: pointer;
    border: none;
    color: white;
    column-gap: 0.3rem;
    align-items: center;
}

.solve-modal-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    bottom: 4rem;
}

.solve-modal-scramble {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.solve-modal-scramble-value {
    text-align: center;
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
}

.solve-scramble-image {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100px;
}
.solve-modal-delete-button{
    // width: 3vw;
    position: relative;
    right: 2.5vw;
}

.solve-modal-save-solves{
    width: 8vw;
}

.solve-modal-scramble-button{
    width: 6vw;
}
.solve-close-button{
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 5px;
    // border: 1px solid black;
    // border-radius: 50%;
    align-items: center;
    svg{
        
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {

    .solve-modal-save-solves{
        width: auto;
    }

    .solve-modal-scramble-button{
        width: auto;
    }

    .solve-modal-delete-button{
        // width: 8vw;
        right: 10vw;
        padding: 10px;
    }

    .solve-modal-scramble {
        margin-top: 4rem;
    }

    .solve-modal-buttons {
        bottom: 2rem;
    }

    .solve-modal-box {
        bottom: 1rem;
    }

    .solve-modal-share-link,
    .solve-modal-btn {
        padding: 0.5rem;
    }
    // .solves-export-button{
    //     margin-bottom: 10%;
    // }
    .solvemodal-svg{
        // border: 2px solid red;
        margin-top: 40px;
        margin-bottom: 30px;
    }
}


.scramble {
  color: white;
}
.cube_class {
  width: 70%;
  /* padding: -50px; */
  max-width: 400px; /* Set a maximum width to maintain the square shape */
  min-width: 100px;
  aspect-ratio: 1/1; /* Maintain a square shape */
  /* border: 2px solid red; */
}

@media (max-width: 768px) { 
  .cube_class {
    bottom: -1rem;
  }
  
}

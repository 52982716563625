.main {
  height: 100vh;
  display: flex;
  // background-color: rgba(0,0,0,0.5);
  // position: fixed;
  // display: none;
}

.main-mobile{
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
}
.app-layout{
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1003;
}

.main-timer-mobile-full{
  overflow: auto;
  // height: 100vh;
  height: 100vh; /* Set parent container height to full viewport */
}

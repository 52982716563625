.profile-form-modal{
    margin: 0 2rem;
    border-radius: 12px;
    
}

@media screen and (max-width: '768px') {
    .profile-form-modal{
        margin: 0px 0rem;
        // height: 60vh;
        top:0%;
    }
}
.import-modal-heading,
.import-modal-sub-heading,
.import-modal-select-file {
  font-family: Nunito Sans;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0em;
}

.import-modal-select-file {
  font-size: 24px;
}

.import-modal-close-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.import-modal-sub-heading {
  font-size: 21px;
}

.import-modal-steps {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  position: relative;
  left: 3vh;
  margin-bottom: 4vh;
}

.import-modal-step {
  font-family: Nunito Sans;
  font-size: 18px;
}

.import-modal-horizontal-line {
  width: 100%;
  border: none;
  height: 3px;
  padding: 0;
  margin: 30px 0;
  background: #1e242c;
  position: absolute;
  bottom: -2.5rem;
}

.file-uploader {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 4vh;
}

.file-uploader p {
  margin: 0;
}

.file-uploader:hover {
  border-color: #aaa;
}

.file-uploader input[type="file"] {
  display: none;
}

.import-modal-link {
  opacity: .7;
  text-decoration: underline;
  color: #ffffff;
}

.import-modal-solves-sessions-data {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.import-modal-solve-value,
.import-modal-session-value,
.import-modal-cubetype-value {
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0em;
}

.import-modal-cubetype-value {
  position: relative;
  left: 4.5rem;
}

.import-modal-imported-data-headings,
.import-modal-imported-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.import-modal-btn {
  margin: 2rem 0;
}

.import-modal-cubetype-value {
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0em;
}

.import-modal-session-value input {
  font-family: Nunito Sans;
  font-weight: 700;
  background: rgb(155 152 152 / 55%);
  border: none;
  height: 30px;
  border-radius: 8px;
  font-size: 18px;
  padding: 0.2rem 0.5rem;
color: #ffffff;
}

.import-modal-no-data {
  font-family: Nunito Sans;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 2rem 0;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {

  .import-modal-heading,
  .import-modal-select-file {
    font-size: 24px;
  }

  .import-modal-sub-heading {
    font-size: 22px;
  }

  .import-modal-select-file {
    font-size: 20px;
  }

  .import-modal-steps {
    left: 0;
  }

  .import-modal-solve-value,
  .import-modal-session-value {
    font-size: 16px;
  }

  .import-modal-cubetype-value {
    font-size: 16px;
  }

  .import-modal-cubetype-value {
    left: 1rem;
  }

  .import-modal-session-value input {
    width: 25vw;
  }

}

.text-color-light {
  color: #000 !important;
}
.glossary-container {
    width: 100%;
    // padding: 0rem 2rem;
    overflow: auto;
    scroll-behavior: smooth;
}
.glossary-header {
    top: 0;
}

@media screen and (max-width: 768px){
    .glossary-container{
        padding-bottom: 3rem;
    }
    .glossary-header{
        top: 4rem;
    }
}


.glossary-heading {
    padding: 0rem 2rem;
    padding-top: 1rem;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.glossary-btn {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.glossary-btn button {
    cursor: pointer;
    width: 133px;
    height: 32px;
    padding: 5px 15px 5px 15px;
    border-radius: 10px;
    gap: 5px;
    border: none;
}

.glossary-box,
.glossary-content {
    display: flex;
    flex-direction: column;
    padding: 0 18px;
    padding-top: 10px;
    // margin-top: 1rem;
}

.glossary-box {
    margin-bottom: 2rem;
    margin: 1rem 0;
    gap: 1rem;
    margin-top: 10rem;
}

.glossary-box {
    padding: 0rem 2rem;
}

.glossary-content h1 {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 0px;
    letter-spacing: 1px;
    text-align: left;
    // color: #ffffff;
}

.glossary-content p {
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 400;
    // line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    // color: #FFFFFFCC;
}

@media screen and (max-width: 768px) {

    .glossary-container {
        padding: 0rem;
        margin-bottom: 4rem;
        padding-bottom: 2rem;
    }

    .glossary-heading {
        padding: 0rem 1rem;
    }

    .glossary-btn {
        padding: 0rem 1rem;
    }

    .glossary-box {
        padding: 0rem 1rem;
        gap: 0.5rem;
        margin-top: 13rem;
    }

    .glossary-content p {
        font-size: 14px;
        line-height: 20px;
        position: relative;
        bottom: 1rem;
    }

    .glossary-content h1 {
        line-height: 23px;
        font-size: 18px;
    }
}
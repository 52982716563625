.modal-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 2rem;
        border-radius: 12px;
        outline: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

.btn-otp {
    width: 60%;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 5px;
    border: none;
    gap: 5px;
    font-size: 15px;
    font-weight: 600;
}

.modal-wrapper input {
   height: 2rem;
   font-size: 1rem;
   border-radius: 10px;
}
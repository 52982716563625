.loader-mobile img{
    width: 30vw;
}

@media screen and (max-width: 768px) {
    .loader-hide{
        display: none;
    }

    .loader-mobile img{
        // height: 35vh;
        width: 20rem;
    }
}



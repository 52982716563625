.account-form input,
.account-form input:focus {
    height: 25px !important;
}

.stdropdown-tools {
    display: none;
}

.stsearch-box {
    padding: 0 !important;
    
}

// .profile-date-picker {
//     width: 95%;
// }

.stdropdown-container {
    border: 0px !important;
}


.stsearch-box input {
    border: 0px !important;
}
@import "../../../assets/styles/variables";

.hidden {
    display: none !important;
}

.position-focus {
    position: absolute;
    top: 0;
}

.timer-top-right:focus {
    outline: none !important;
    border: none !important;
}


.top-corner {
    justify-content: end;
    height: auto !important;
}

.top-corner-mobile {
    justify-content: end;
}

.mobile-top-bar {
    // border: 2px solid red;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// changed for mobile
@media screen and (max-width: 768px) {
    // .mobile-top-bar {
    //     height: 10vh;
    // }

    .position-focus-mobile {
        position: absolute;
    }
}

.mobile-ham {
    img {
        margin-left: 10px;
        width: 12vw;
    }
}

.mobile-top-logo {
    padding: 10px;

    img {
        width: 40vw;
    }
}

.mobile-profile {
    img {
        margin-right: 10px;
        width: 8vw;
        border-radius: 50px;
    }
}

.new-session {
    background-color: #e817ba;
    padding: 2vh;
}

.create-session-main {
    // height: vh;
    width: 40vw;
    aspect-ratio: 6/3;
    position: absolute;
    z-index: 1000;
    border-radius: 30px;
    padding: 0 0 0 10px;
    // left: 22vw;
    margin-top: 5px;
}

.CNS-title {
    margin: 0 auto;
    width: 88%;
    font-size: 1.7vw;
    font-weight: bold;
    font-family: "Nunito Sans bold", sans-serif;
    // padding: 0 1vw;
}

.CNS-cancel-div {
    padding: 0px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.CNS-desc {
    //   border: 2px solid red;
    margin: 1vh auto;
    // padding: 0 1vw;
    font-size: 0.9rem;
    width: 35vw;
}

.CNS-input-div {
    margin: 5vh auto;
    width: 90%;
    display: flex;
    // border: 2px solid red;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.CNS-session-text {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 550;
    font-size: 0.5 rem;
    padding-left: 2px;
}

.CNS-input {
    width: 35vw;
    aspect-ratio: 25/1.6;
    border-radius: 10px;
    padding-left: 1vw;
}

.CNS-cubetype-div {
    width: 90%;
    margin: 0 auto;
}

.CNS-cube-text {
    font-weight: 550;
    font-family: "Nunito Sans", sans-serif;
}

.italic {
    font-style: italic;
}

.CNS-select {
    // color: white;
    font-weight: bold;
}

.CNS-cube-select {
    margin: 5px 0;
}

.CNS-button {
    background-color: #00cc00;
    width: 5.5vw;
    aspect-ratio: 7/3;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.CNS-button-div {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2vh;
}

.display-none {
    display: none;
}

/* Add styles for the input error and character limit error message */
.CNS-input.input-error {
    border-bottom: 2px solid red;
}

.character-limit-error {
    color: red;
    font-size: 14px;
    margin-top: 4px;
}

.matrix-cubetype {
    overflow-y: scroll;
    height: 30vh;
    font-size: 1em;
    position: absolute;
    top: 2.2rem;
    left: 0;
    border-radius: 10.44px;
    z-index: 1000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 6px 10px;
    font-family: "Nunito Sans";
    font-weight: 700;
}

.dropdown-matrix-cubetype {
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.matrix-cubetype-padding-bottom {
    padding-bottom: 5px;
}

@media screen and (max-width: 768px) {
    .matrix-cubetype {
        gap: 1rem;
    }

    .matrix-cubetype-padding-bottom {
        padding-bottom: 10px;
    }

    .dropdown-matrix-cubetype,
    .matrix-cubetype {
        font-size: 15px;
    }

}

.dropdown-tick {
    width: 1em;
    height: 1em;
    // fill: white;
}

.cube-type-select {
    border: none;
    cursor: pointer;
    width: 9vw;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    font-family: "Nunito Sans";
    font-weight: 700;
    font-size: 1em;
}

// .session-select {
//     height: 2.3vw;
// }

// changed for mobile
@media screen and (max-width: 768px) {
    .timer-top-bar {
        // margin-bottom: 5rem;
        margin-bottom: 0vh;
        position: relative;
        // bottom: 2rem;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none
    }

}

@media only screen and (max-width: $mobile-screen-width-timer-max) {

    .session-select {
        // height: 32px;
        height: auto;
        width: 140px;
    }

    .cube-type-select {
        width: 120px;
    }

    .session-select {
        select {
            width: 30vw;
        }
    }

    // .timer-top-left {
    //     // border: 2px solid red;
    //     width: 45vw;
    // }

    .focus-button {
        // border-radius: 50px;
        // padding: 5px;
        // margin-right: 4vw;

        // border: 2px solid red;
        user-select: none;
        -webkit-touch-callout: none;

        -webkit-user-select:none img {
            margin: -4px;
            width: 1.1rem;
        }
    }

    .create-session-main {
        // height: vh;
        width: 90vw;
        min-width: 200px;
        max-width: 350px;
        aspect-ratio: 6/3;
        // left: 20%;
    }

    .CNS-title {
        font-size: 1rem;
    }

    .CNS-desc {
        width: 95%;
        line-height: 0.9rem;
        font-size: 0.8rem;
    }

    .CNS-session-text {
        // margin: 0;
        font-size: 0.8rem;
    }

    .CNS-input {
        width: 60vw;
        aspect-ratio: 25/2;
        border-radius: 5px;
        padding: 1vw 0;
        padding-left: 2vw;
    }


    .CNS-button {
        width: 15vw;
        min-width: 50px;

    }

    .CNS-button-div {
        // border: 2px solid red;
        margin-bottom: 2vh;
    }

    .mobile-top-overlay {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px 10.96px 10.96px 0px;
    // background: rgba(255, 255, 255, 0.4);
}

#style-1::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px 10.96px 10.96px 0px;
    // background: linear-gradient(270deg, #0A003A 0%, #692BCB 100%);
    background: rgba(44, 44, 44, 0.1);

}

#style-1::-webkit-scrollbar-thumb {
    padding: 5px 0;
    border-radius: 0px 10.96px 10.96px 0px;
    background: rgba(0, 0, 0, 0.574);
}

.mobile-dropdown-timer {
    display: none;
}


@media screen and (max-width: 768px) {
    .mobile-dropdown-timer {
        display: flex;
    }

    .desktop-dropdown-timer {
        display: none;
    }

}

.light-selected {
    color: purple;
    font-weight: 1000;
}